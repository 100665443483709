import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './styled.module.scss';
import ReactGA from 'react-ga4';

const Home = () => {
  useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  return (
    <>
      <Row className={styles.rowContainer}>
        <Col span={24} className={styles.container}>
          <img src="/GHOST/BGHome.png" width={'auto'} height={'100%'} />
        </Col>
        <Col span={20} className={styles.blockImage}>
          <div className={styles.blockLeft}>
            <h1>SECURED ANONYMITY</h1>
            <a>
              TRUSTED BY TOP FIRMS <br />
              AROUND THE WORLD
            </a>
            <p>
              Built from the ground up and designed <br /> for maximum security.
            </p>
          </div>
          <div className={styles.blockRight}>
            <img src="/GHOST/ImgHome.png" width={'548px'} height={'719px'} />
          </div>
        </Col>
      </Row>
      <Row className={styles.rowContainerFive}>
        <Col span={24} className={styles.colContainerFive}>
          <img src="/GHOST/Section.png" height={'350px'} width={'100%'} />
        </Col>
      </Row>
      <Row className={styles.rowContainerSix}>
        <Col span={20} className={styles.colContainerSix}>
          <div className={styles.blockContentLeftSix}>
            <a>GHOST Messenger</a>
            <p>
              Encrypted messages, file transfers, calls and video conferences,
              all integrated into a super app that secures and encrypts all your
              communication. State-of-the-art technology ensures your every
              interaction with other users is protected. No one, including us,
              can listen to your calls, read your messages or compromise your
              file transfers.
            </p>
          </div>
          <div className={styles.ConverImagePhoneHome}>
            <img src="/GHOST/PhoneHome.png" width={'auto'} height={'500pxpx'} />
          </div>
        </Col>
        <div className={styles.ConverLogo}>
          <img src="/GHOST/Shape.png" width={'auto'} height={'300pxpx'} />
        </div>
        <div className={styles.ConverTitleBottom}>
          <a>
            Our GHOST Messenger, provide the user with the most complete mobile
            security solution available.
          </a>
        </div>
      </Row>
    </>
  );
};
export default Home;

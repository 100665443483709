import React from 'react';
import styled from './styled.module.scss';
import { Col, Row } from 'antd';

const Footer = () => {
  return (
    <>
      <Col>
        <div className={styled.ConverFooter}>
          <div className={styled.WrapperWithoutGG}>
            <div className={styled.ConverDownloadLeft}>
              <a
                className={styled.DownloadImages}
                href="https://chat.goghost.org/released/app-release-06-11.apk"
              >
                <img src="/GHOST/LogoGoogleChBan.png" width={'100%'} />
              </a>
            </div>
            <div className={styled.ConverLogoBootom}>
              <img src="/GHOST/LogoFooter.png" width={'72px'} height={'auto'} />
              <p>©2022 GHOST</p>
            </div>
          </div>
          <div className={styled.ConverDownload}>
            <a
              className={styled.DownloadImages}
              href="https://install.goghost.org/2022"
              target="_blank"
            >
              <img src="/GHOST/LogoGoogleCh.png" width={'100%'} />
            </a>
            <a
              className={styled.DownloadImages}
              href="https://apps.apple.com/gb/app/ghost-messenger-ios/id6444491082?uo=2"
              target="_blank"
            >
              <img src="/GHOST/LogoAppStore.png" width={'100%'} />
            </a>
          </div>
        </div>
      </Col>
    </>
  );
};
export default Footer;

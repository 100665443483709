import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Link, Route } from 'react-router-dom';
import Home from './containers/Home';
import RAndD from './containers/R&D';
import Agency from './containers/Agency';
import GhostPolicy from './containers/GhostPolicy';
import GhostLegalPage from './containers/TheLegal';
import AboutContact from './containers/AboutContact';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
  return (
    <>
      <div className="App">
        <TawkMessengerReact
            propertyId="6690f541c3fb85929e3e7050"
            widgetId="1i2j3s2lo"/>
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="r-and-d" element={<RAndD />} />
        <Route path="/about-us" element={<Agency />} />
        {/* <Route path="privacy-policy" element={<GhostPolicy />} /> */}
        <Route path="legal" element={<GhostLegalPage />} />
        <Route path="contact" element={<AboutContact />} />
      </Routes>
    </>
  );
}

export default App;

import React, { useState } from 'react';
// import "./Slide.css";
import styled from './styled.module.scss';
import { Link } from 'react-router-dom';

const Header = () => {
  const [visible, setVisible] = useState(false);
  const [animation, setAnimation] = useState(true);
  const handleConverHeader = () => {
    setVisible(!visible);
    setAnimation(!animation);
  };
  return (
    <>
      <div className={styled.WrapperHeader}>
        <div
          className={visible ? styled.CoverheaderHidden : styled.Coverheader}
        >
          <a href="/" className={styled.ConverLinkLogo}>
            <div className={styled.ConverLogo}>
              <img src="/GHOST/Logo_Icon.png" width={'74px'} height={'auto'} />
              <p>GHOST</p>
            </div>
          </a>
          <div className={styled.ConverMenuHeader}>
            <div className={styled.blockItemHeader}>
              {/* Block child */}
              <div className={styled.dropdown}>
                <Link to="/">
                  <div className={styled.blockHeader}>Home</div>
                </Link>
              </div>
            </div>
            <div className={styled.blockItemHeader}>
              {/* Block child */}
              <div className={styled.dropdown}>
                <Link to="/r-and-d">
                  <div className={styled.blockHeader}>R&D</div>
                </Link>
              </div>
            </div>
            <div className={styled.blockItemHeader}>
              {/* Block child */}
              <div className={styled.dropdown}>
                <Link to="/about-us">
                  <div className={styled.blockHeader}>About Us</div>
                </Link>
              </div>
            </div>
            <div className={styled.blockItemHeader}>
              {/* Block child */}
              <div className={styled.dropdown}>
                <Link to="/legal">
                  <div className={styled.blockHeader}>Legal</div>
                </Link>
              </div>
            </div>

            <div className={styled.blockItemHeader}>
              {/* Block child */}
              <div className={styled.dropdown}>
                <Link to="/contact">
                  <div className={styled.blockHeader}>Contact</div>
                </Link>
              </div>
            </div>
          </div>
          <div className={styled.ConverMenuHeader}></div>
          <div
            className={styled.ConverMenuHover}
            onClick={() => handleConverHeader()}
          >
            <span className={styled.ConverIconBar}></span>
            <span
              className={visible ? styled.ConverIconBar2 : styled.ConverIconBar}
            ></span>
            <span
              className={visible ? styled.ConverIconBar3 : styled.ConverIconBar}
            ></span>
          </div>
        </div>
        <div
          className={animation ? styled.ConverShowMenu : styled.ConverHidenMenu}
        >
          <div
            className={
              animation ? styled.ConverMenuTitle : styled.ConverHidenMenuTitle
            }
          >
            <ul onClick={() => handleConverHeader()}>
              <li>
                {' '}
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/legal">Legal</Link>
              </li>
              <li>
                <Link to="/r-and-d">R&D</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <img src="/GHOST/Logo_Icon.png" width={'75px'} height={'auto'} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;

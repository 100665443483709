import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import styles from './styled.module.scss';
import ReactGA from 'react-ga4';

const GhostLegalPage = () => {
  useEffect(() => {
    ReactGA.send('pageview');
  }, []);
  return (
    <>
      {' '}
      <Row className={styles.rowContainer}>
        <Col xs={20} xl={16} className={styles.container}>
          <p>
            GHOST is designed to never collect or store any sensitive
            information. GHOST messages and calls cannot be accessed by us or
            other third parties because they are always end-to-end encrypted,
            private, and secure. Our Terms of Service and Privacy Policy are
            available below.
          </p>
          <ul id="/terms-of-service">
            <li>
              <a href="/legal/#terms-of-service">Terms of Service</a>
            </li>
            <li>
              <a href="/legal/#privacy-policy">Privacy Policy</a>
            </li>
          </ul>
          <h1>Terms of Service </h1>
          <p>
            Ghost Agency Limited. (“GHOST”) utilizes state-of-the-art security
            and end-to-end encryption to provide private messaging, Internet
            calling, and other services to users worldwide. You agree to our
            Terms of Service (“Terms”) by installing or using our apps,
            services, or website (together, “Services”).
          </p>
          <h2>About our services</h2>
          <p>
            <span>Minimum Age.</span> You must be at least 13 years old to use
            our Services. The minimum age to use our Services without parental
            approval may be higher in your home country.
          </p>
          <p>
            <span>Privacy of user data.</span> GHOST does not sell, rent or
            monetize your personal data or content in any way – ever.
          </p>
          <p>
            Please read our <a href="/#privacy-policy">Privacy Policy</a> to
            understand how we safeguard the information you provide when using
            our Services. For the purpose of operating our Services, you agree
            to our data practices as described in our Privacy Policy, as well as
            the transfer of your encrypted information and metadata to Hong Kong
            and other countries where we have or use facilities, service
            providers or partners. Examples would be Third Party Providers
            sending you a verification code and processing your support tickets.
          </p>
          <p>
            <span>Software.</span> In order to enable new features and enhanced
            functionality, you consent to downloading and installing updates to
            our Services.
          </p>
          <p>
            <span>Fees and Taxes.</span> You are responsible for data and mobile
            carrier fees and taxes associated with the devices on which you use
            our Services.
          </p>
          <h2>Using GHOST</h2>
          <p>
            <span>Our Terms and Policies.</span> You must use our Services
            according to our Terms and posted policies. If we disable your
            account for a violation of our Terms, you will not create another
            account without our permission.
          </p>
          <p>
            <span>Legal and Acceptable Use.</span> You agree to use our Services
            only for legal, authorized, and acceptable purposes. You will not
            use (or assist others in using) our Services in ways that: (a)
            violate or infringe the rights of GHOST, our users, or others,
            including privacy, publicity, intellectual property, or other
            proprietary rights; (b) involve sending illegal or impermissible
            communications such as bulk messaging, auto-messaging, and
            auto-dialing.
          </p>
          <p>
            <span>Harm to GHOST.</span> You must not (or assist others to)
            access, use, modify, distribute, transfer, or exploit our Services
            in unauthorized manners, or in ways that harm GHOST, our Services,
            or systems. For example you must not (a) gain or try to gain
            unauthorized access to our Services or systems; (b) disrupt the
            integrity or performance of our Services; (c) create accounts for
            our Services through unauthorized or automated means; (d) collect
            information about our users in any unauthorized manner; or (e) sell,
            rent, or charge for our Services.
          </p>
          <p>
            <span>Keeping Your Account Secure.</span> GHOST embraces privacy by
            design and does not have the ability to access your messages. You
            are responsible for keeping your device and your GHOST account safe
            and secure. If you lose your phone, follow the steps on our Support
            site to re-register for our Services. When you register with a new
            device, your old device will stop receiving all messages and calls.
          </p>
          <p>
            <span>No Access to Emergency Services.</span> Our Services do not
            provide access to emergency service providers like the police, fire
            department, hospitals, or other public safety organizations. Make
            sure you can contact emergency service providers through a mobile,
            fixed-line telephone, or other service.
          </p>
          <p>
            <span>Third-party services.</span> Our Services may allow you to
            access, use, or interact with third-party websites, apps, content,
            and other products and services. When you use third-party services,
            their terms and privacy policies govern your use of those services.
          </p>
          <h2>Your Rights and License with GHOST</h2>
          <p>
            <span>Your Rights.</span> You own the information you submit through
            our Services. You must have the rights to the phone number you use
            to sign up for your GHOST account.
          </p>
          <p>
            <span>GHOST’s Rights.</span> We own all copyrights, trademarks,
            domains, logos, trade dress, trade secrets, patents, and other
            intellectual property rights associated with our Services. You may
            not use our copyrights, trademarks, domains, logos, trade dress,
            patents, and other intellectual property rights unless you have our
            written permission. To report copyright, trademark, or other
            intellectual property infringement, please contact
            abuse@goghost.org.
          </p>
          <p>
            <span>GHOST’s License to You.</span> GHOST grants you a limited,
            revocable, non-exclusive, and non-transferable license to use our
            Services in accordance with these Terms.
          </p>
          <h2>Disclaimers and Limitations</h2>
          <p>
            <span>Disclaimers.</span> YOU USE OUR SERVICES AT YOUR OWN RISK AND
            SUBJECT TO THE FOLLOWING DISCLAIMERS. WE PROVIDE OUR SERVICES ON AN
            “AS IS” BASIS WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING,
            BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND FREEDOM FROM
            COMPUTER VIRUS OR OTHER HARMFUL CODE. GHOST DOES NOT WARRANT THAT
            ANY INFORMATION PROVIDED BY US IS ACCURATE, COMPLETE, OR USEFUL,
            THAT OUR SERVICES WILL BE OPERATIONAL, ERROR-FREE, SECURE, OR SAFE,
            OR THAT OUR SERVICES WILL FUNCTION WITHOUT DISRUPTIONS, DELAYS, OR
            IMPERFECTIONS. WE DO NOT CONTROL, AND ARE NOT RESPONSIBLE FOR,
            CONTROLLING HOW OR WHEN OUR USERS USE OUR SERVICES. WE ARE NOT
            RESPONSIBLE FOR THE ACTIONS OR INFORMATION (INCLUDING CONTENT) OF
            OUR USERS OR OTHER THIRD PARTIES. YOU RELEASE US, AFFILIATES,
            DIRECTORS, OFFICERS, EMPLOYEES, PARTNERS, AND AGENTS (TOGETHER,
            “GHOST PARTIES”) FROM ANY CLAIM, COMPLAINT, CAUSE OF ACTION,
            CONTROVERSY, OR DISPUTE (TOGETHER, “CLAIM”) AND DAMAGES, KNOWN AND
            UNKNOWN, RELATING TO, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH
            ANY SUCH CLAIM YOU HAVE AGAINST ANY THIRD PARTIES.
          </p>
          <p>
            <span>Limitation of liability.</span> THE GHOST PARTIES WILL NOT BE
            LIABLE TO YOU FOR ANY LOST PROFITS OR CONSEQUENTIAL, SPECIAL,
            PUNITIVE, INDIRECT, OR INCIDENTAL DAMAGES RELATING TO, ARISING OUT
            OF, OR IN ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES,
            EVEN IF THE GHOST PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES. OUR AGGREGATE LIABILITY RELATING TO, ARISING OUT OF,
            OR IN ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES WILL
            NOT EXCEED ONE HUNDRED DOLLARS ($100). THE FOREGOING DISCLAIMER OF
            CERTAIN DAMAGES AND LIMITATION OF LIABILITY WILL APPLY TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. THE LAWS OF SOME STATES
            OR JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF
            CERTAIN DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS
            SET FORTH ABOVE MAY NOT APPLY TO YOU. NOTWITHSTANDING ANYTHING TO
            THE CONTRARY IN OUR TERMS, IN SUCH CASES, THE LIABILITY OF THE GHOST
            PARTIES WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY
            APPLICABLE LAW.
          </p>
          <p>
            <span>Availability of Our Services.</span> Our Services may be
            interrupted, including for maintenance, upgrades, or network or
            equipment failures. We may discontinue some or all of our Services,
            including certain features and the support for certain devices and
            platforms, at any time.
          </p>
          <h2>Resolving Disputes and Ending Terms</h2>
          <p>
            <span>Resolving disputes.</span> You agree to resolve any Claim you
            have with us relating to or arising out of our Terms, us, or our
            Services exclusively in the Hong Kong court system. You also agree
            to submit to the personal jurisdiction of such courts for the
            purpose of litigating all such disputes. The laws of Hong Kong
            govern our Terms, as well as any disputes, whether in court or
            arbitration, which might arise between GHOST and you, without regard
            to conflict of law provisions.
          </p>
          <p>
            <span>Ending these Terms.</span> You may end these Terms with GHOST
            at any time by deleting GHOST Messenger from your device and
            discontinuing use of our Services. We may modify, suspend, or
            terminate your access to or use of our Services anytime for any
            reason, such as if you violate the letter or spirit of our Terms or
            create harm, risk, or possible legal exposure for GHOST. The
            following provisions will survive termination of your relationship
            with GHOST: “Licenses,” “Disclaimers,” “Limitation of Liability,”
            “Resolving dispute,” “Availability” and “Ending these Terms,” and
            “General”.
          </p>
          <h2>General</h2>
          <p>
            GHOST may update the Terms from time to time. When we update our
            Terms, we will update the “Last Modified” date associated with the
            updated Terms. Your continued use of our Services confirms your
            acceptance of our updated Terms and supersedes any prior Terms. You
            will comply with all applicable export control and trade sanctions
            laws. Our Terms cover the entire agreement between you and GHOST
            regarding our Services. If you do not agree with our Terms, you
            should stop using our Services.
          </p>
          <p id="privacy-policy">
            If we fail to enforce any of our Terms, that does not mean we waive
            the right to enforce them. If any provision of the Terms is deemed
            unlawful, void, or unenforceable, that provision shall be deemed
            severable from our Terms and shall not affect the enforceability of
            the remaining provisions. Our Services are not intended for
            distribution to or use in any country where such distribution or use
            would violate local law or would subject us to any regulations in
            another country. We reserve the right to limit our Services in any
            country. If you have specific questions about these Terms, please
            contact us at privacy@goghost.org.
          </p>
          <h2>Privacy Policy</h2>
          <p>
            GHOST utilizes state-of-the-art security and end-to-end encryption
            to provide private messaging and Internet calling services to users
            worldwide (“Services”). Your calls and messages are always
            encrypted, so they can never be shared or viewed by anyone but
            yourself and the intended recipients.
          </p>
          <h2>Information you provide</h2>
          <p>
            <span>Messages.</span> GHOST cannot decrypt or otherwise access the
            content of your messages or calls. GHOST queues end-to-end encrypted
            messages on its servers for delivery to devices that are temporarily
            offline (e.g. a phone whose battery has died). Additional technical
            information is stored on our servers, including randomly generated
            authentication tokens, keys, push tokens, and other material that is
            necessary to establish calls and transmit messages. GHOST limits
            this additional technical information to the minimum required to
            operate the Services.
          </p>
          <p>
            <span>User Support.</span> If you contact GHOST User Support, any
            personal data you may share with us is kept only for the purposes of
            researching the issue and contacting you about your case.
          </p>
          <h2>Information we may share</h2>
          <p>
            <span>Third Parties.</span> We work with third parties to provide
            some of our Services. For example, our presence on Google and
            Apple’s App Store. These providers are bound by their Privacy
            Policies to safeguard that information.
          </p>
          <p>
            <span>Other instances where GHOST may need to share your data</span>
          </p>{' '}
          <ul>
            <li>
              To meet any applicable law, regulation, legal process or
              enforceable governmental request.
            </li>
            <li>
              To enforce applicable Terms, including investigation of potential
              violations.
            </li>
            <li>
              To detect, prevent, or otherwise address fraud, security, or
              technical issues.
            </li>
            <li>
              To protect against harm to the rights, property, or safety of
              GHOST, our users, or the public as required or permitted by law.
            </li>
          </ul>
          <h2>Updates</h2>
          <p>
            We will update this privacy policy as needed so that it is current,
            accurate, and as clear as possible. Your continued use of our
            Services confirms your acceptance of our updated Privacy Policy.
          </p>
          <h2>Terms</h2>
          <p>
            Please also read our <a href="/#Terms_of_Service">Terms</a> which
            also governs the terms of this Privacy Policy.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have questions about our Privacy Policy please contact us at
            privacy@goghost.org
          </p>
          <p>Effective as of May 10, 2023</p>
          <p>Updated May 10, 2023</p>
          {/* ===============End============== */}
        </Col>
      </Row>
    </>
  );
};
export default GhostLegalPage;

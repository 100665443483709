import React from 'react';
import styled from './styled.module.scss';
import { Col, Row } from 'antd';

const Agency = () => {
  return (
    <>
      <div className={styled.WrapperAgency}>
        <div className={styled.ConverColor}></div>
        <div className={styled.ConverWidth}>
          <div className={styled.ConverPoster}>
            <div className={styled.ConverTitleAgen}>
              ABOUT
              <br /> US
            </div>
            <div className={styled.ConverImages}>
              <img src="/GHOST/Agency.png" width={'500px'} height={'auto'} />
            </div>
          </div>
        </div>
      </div>
      <Row className={styled.rowContainer}>
        <Col xs={20} xl={16} className={styled.container}>
          <a>Who We Are</a>
          <p>
            At GHOST we are mobile security experts whose sole mission is to
            preserve the anonymity of our clients and to ensure the security and
            integrity of their data.
          </p>
          <a>Why GHOST</a>
          <p>
            It takes just one lapse in anonymity to undo years of trying to be
            anonymous. If you are a high-profile person and your online presence
            is not private, you and your organization will most likely be
            targeted by malicious attackers. According to the World Economic
            Forum, 95% of cybersecurity breaches are caused by human errors,
            which proves statistically that the weakest link in a system's
            security is the user and not the system itself.
          </p>
          <p>
            At the Agency, we take measures to completely remove the possibility
            of human errors so your organization can securely operate in the new
            age of the Internet.
          </p>
          <a>GHOST Difference</a>
          <p>
            Security has and will always be layered, and even when you stack
            many layers and those layers are tight, you could be exposed and not
            even know it. The Agency recognizes this and has chosen this to be
            our task. Our team works full time to research and innovate to
            protect our clients in the ever changing and dynamic scene that is
            cybersecurity.
          </p>
          <a>Can I Trust GHOST</a>
          <p>
            The Agency codes with the worst case scenarios in mind. Even if our
            servers were to be compromised, the worst thing the attacker could
            do is delay messages from being sent in a timely fashion.
          </p>
          <p>
            Your data is 100% encrypted before leaving your device meaning any
            data our service facilitate is already encrypted, so even in the
            worst case scenarios we cannot access any of your messages or data.{' '}
          </p>
          <p>
            We cannot send your messages to an unintended recipient as that
            recipient would not have the key required to decrypt your messages.
          </p>
          <p>
            We cannot replay your past messages as each message utilizes a
            uniquely generated sequence. We do not want your data. We only
            collect the minimum to facilitate our services.
          </p>
        </Col>
        <div className={styled.ConverLogo}>
          <img src="/GHOST/Shape.png" width={'auto'} height={'300pxpx'} />
        </div>
      </Row>
    </>
  );
};
export default Agency;

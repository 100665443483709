import React, { useRef, useState, useEffect } from "react";
import styles from "./styled.module.scss";
import { Row, Col, Input, Button, notification, Spin } from "antd";
import emailjs from "@emailjs/browser";
import ReactGA from "react-ga4";

const AboutContact = () => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Success",
      description:
        "Thank you for your feedback, we will contact you as soon as possible.",
    });
  };
  const form = useRef();
  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();

    emailjs
      .sendForm(
        "service_loniryn",
        "template_gq4wuj9",
        form.current,
        "QdAoXTA_Nf8iktmvf"
      )
      .then(
        (result) => {
          console.log(result);
          result.status == 200 && openNotificationWithIcon("success");
          setLoading(false);
        },
        (error) => {
          openNotificationWithIcon("error");
        }
      );
  };
  return (
    <>
      {contextHolder}
      {/* section two */}
      <Row className={styles.SectionTwo}>
        <Col span={18} className={styles.Content}>
          <div className={styles.CarrerContent}>
            <div className={styles.CoverContact}>
              <a>Contact Us</a>
              <hr />
              <p>Share your query and ideas with us!</p>
            </div>
            <div className={styles.ConverImages}>
              <img src="/GHOST/Image.png" width={"100%"} height={"230px"} />
            </div>
          </div>
          <div className={styles.WrapperContact}>
            <form
              ref={form}
              name="nest-messages"
              layout="vertical"
              onSubmit={sendEmail}
            >
              <div className={styles.WrapperForm}>
                <div className={styles.WrapperName}>
                  <label style={{ width: "48%" }}>
                    FName*
                    <Input
                      required={true}
                      name="fname"
                      size="large"
                      placeholder="e.g.John"
                    />
                  </label>
                  <label style={{ width: "48%" }}>
                    LName*
                    <Input
                      name="lname"
                      required={true}
                      size="large"
                      placeholder="e.g.Doe"
                    />
                  </label>
                </div>
                <label>
                  Email*
                  <Input
                    type="email"
                    name="email"
                    required={true}
                    size="large"
                    placeholder="name@domain.com"
                  />
                </label>
                <label>
                  Title*
                  <Input name="title" size="large" placeholder="..." />
                </label>
                <label>
                  Message*
                  <Input.TextArea
                    name="introduction"
                    rows={3}
                    placeholder="Input message"
                  />
                </label>
                <div className={styles.apply}>
                  <Button type="primary" htmlType="submit">
                    SEND MESSAGE
                  </Button>
                  {loading && <Spin style={{ marginTop: "10px" }} />}
                </div>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AboutContact;
